import React from "react";
import { connect } from "react-redux";
import { evoucherUpdate } from "../../actions/EvoucherActions"
import Barcode from "react-barcode"
import TescoLotusEvoucher200 from "../../assets/images/barcode/lotus-e-coupons-200-mazda-v2.jpg";
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import _ from "lodash";
import moment from "moment";
moment.locale('th');

class Mazda2021001 extends React.Component {

    constructor(props) {
        super(props);
        this.state = { term: false, redeem: false };
    }

    render() {
        const { evoucher } = this.props;

        return (
            <div className="row">
                {evoucher && !_.isEmpty(evoucher.barcode) && (
                    <div className="col">
                        <Modal isOpen={this.state.term && !this.state.redeem && evoucher.status != 'redeemed'}   >
                            <ModalBody>
                                <h4><strong>คุณต้องการใช้อี-คูปองบัตรกำนัลเทสโก้โลตัสมูลค่า 200 บาทใช่หรือไม่ ?</strong></h4>
                                <p className="h5 pl-3">กด “ยืนยัน” เพื่อรับบาร์โค้ดในการชำระเงิน</p>
                                <p className="h5 pl-3">กด “ไม่ใช่” หากต้องการใช้ภายหลัง</p>
                                <p className="h5">เมื่อท่านใช้คูปองแลกสิทธิ์แล้ว จะไม่สามารถนำคูปองกลับมาใช้ได้อีก</p>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn-danger btn mr-auto" style={{ fontFamily: "mazdatype", width: 120, backgroundColor: "#8f3705", borderColor: "#8f3705" }}
                                    onClick={() => {
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "redeemed", redeemed_date: new Date() });
                                        this.setState({ redeem: true });
                                    }}
                                >ยืนยัน</button>
                                <button className="btn btn-outline-secondary" style={{ fontFamily: "mazdatype", width: 120 }} onClick={() => { this.setState({ term: false }) }} >ไม่ใช่</button>
                            </ModalFooter>
                        </Modal>
                        <div style={{ position: "relative" }}>
                            <img src={TescoLotusEvoucher200} className="img-fluid" />
                            {(this.state.redeem || evoucher.status == 'redeemed') && (
                                <p className="pt-2 pb-2 pl-4 pr-4" style={{ color: 'red', fontSize: 36, position: 'absolute', bottom: 50, left: 50, border: "3px solid red", transform: "rotate(30deg)" }}>REDEEMED.</p>
                            )}
                        </div>
                        {!this.state.redeem && evoucher.status != 'redeemed' && (
                            <div className="">
                                <div className="mt-4  mb-4" >
                                    <h4 className="text-center mt-4 mb-4">วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`}<br />เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}</h4>
                                    <h4><strong>เงื่อนไขการใช้อี-คูปองบัตรกำนัลเทสโก้โลตัสมูลค่า 200 บาท จากบริษัท มาสด้า เซลส์ (ประเทศไทย) จำกัด</strong></h4>
                                    <ul className="h5 list-unstyled ">
                                        <li className="mt-2">1. กดใช้สิทธิ์ เพื่อรับบาร์โค้ดในการชำระเงิน</li>
                                        <li className="mt-2">2. บาร์โค้ดนี้ใช้ได้เพียงครั้งเดียว</li>
                                        <li className="mt-2">3. อี-คูปองนี้ไม่สามารถเปลี่ยนเป็นเงินสดหรือโอนสิทธิ์ให้แก่ผู้อื่นได้ในทุกกรณี</li>
                                        <li className="mt-2">4. หากมีปัญหาในการใช้อี-คูปอง ติดต่อได้ที่ 090-747-7980</li>
                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn-lg h2" onClick={() => {
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "opened", opened_date: new Date() });
                                        this.setState({ term: true });
                                    }}>กดใช้สิทธิ์</button>
                                </div>
                            </div>
                        )}
                        {(this.state.redeem || evoucher.status == 'redeemed') && (
                            <div className="text-center mt-4">
                                <Barcode value={evoucher.barcode} />
                                <h4 className="mt-2">โปรดแสดงบาร์โค้ดนี้ ณ จุดรับชำระเงิน</h4>
                                <h4 className="mt-4">วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`}<br />เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}</h4>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({ evoucher: { evoucher } }) => {
    return { evoucher };
};

export default connect(
    mapStateToProps, { evoucherUpdate }
)(Mazda2021001);