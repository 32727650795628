import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { REPORT_EVOUCHER_RESPONSERATE_FETCH, REPORT_EVOUCHER_RESPONSERATE_EXPORT_EXCEL } from "../actions/types";
import { reportEvoucherResponserateFetchSuccess } from "../actions/ReportActions";
import { showErrorMessage, showSuccessMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import axios from "axios";
import FileSaver from "file-saver";
import moment from "moment";
import _ from "lodash";

// API
export const reportEvoucherResponserateFetchRequest = async payload => {
    let url = `${process.env.REACT_APP_API_URL}/api/evouchers/report/responserate`;

    let params = { ...payload };
    params = _.omitBy(params, _.isEmpty);

    return await axios
        .get(url, {
            headers: authorizationHeader("bearer"),
            params: params
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

export const reportEvoucherResponserateExportExcelRequest = async payload => {
    let url = `${process.env.REACT_APP_API_URL}/api/evouchers/report/excel`;
    let params = { ...payload };
    params = _.omitBy(params, _.isEmpty);

    return await axios
        .get(url, {
            headers: authorizationHeader("bearer"),
            params: params,
            responseType: "blob"
        })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

// WORKER
function* reportEvoucherResponserateFetch({ payload }) {
    try {
        payload.from_surveyed_date = payload.from_surveyed_date instanceof Object ? payload.from_surveyed_date.format("YYYY-MM-DD") : payload.from_surveyed_date;
        payload.to_surveyed_date = payload.to_surveyed_date instanceof Object ? payload.to_surveyed_date.format("YYYY-MM-DD") : payload.to_surveyed_date;

        const data = yield call(reportEvoucherResponserateFetchRequest, payload);
        yield put(reportEvoucherResponserateFetchSuccess(data));
        yield put(hideLoader());
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

function* reportEvoucherResponserateExportExcel({ payload }) {
    try {
        moment.locale('en');
        payload.from_surveyed_date = payload.from_surveyed_date instanceof Object ? payload.from_surveyed_date.format("YYYY-MM-DD") : payload.from_surveyed_date;
        payload.to_surveyed_date = payload.to_surveyed_date instanceof Object ? payload.to_surveyed_date.format("YYYY-MM-DD") : payload.to_surveyed_date;

        let report_month = moment(payload.from_surveyed_date);
        const data = yield call(reportEvoucherResponserateExportExcelRequest, payload);
        FileSaver.saveAs(data, `MPI - ${report_month.format('MMM’YY').toUpperCase()}.xlsx`);
        yield put(hideLoader());
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

// WATCHER
function* reportEvoucherResponserateFetchWatcher() {
    yield takeEvery(REPORT_EVOUCHER_RESPONSERATE_FETCH, reportEvoucherResponserateFetch);
}


function* reportEvoucherResponserateExportExcelWatcher() {
    yield takeEvery(REPORT_EVOUCHER_RESPONSERATE_EXPORT_EXCEL, reportEvoucherResponserateExportExcel);
}

export default function* rootSaga() {
    yield all([
        fork(reportEvoucherResponserateFetchWatcher),
        fork(reportEvoucherResponserateExportExcelWatcher)
    ]);
}