import {
    UPLOADS_FETCH,
    UPLOADS_FETCH_SUCCESS,
    UPLOADS_FETCH_RESET
} from "./types";

export const uploadsFetch = payload => {
    return {
        type: UPLOADS_FETCH,
        payload: payload
    };
};

export const uploadsFetchSuccess = payload => {
    return {
        type: UPLOADS_FETCH_SUCCESS,
        payload: payload
    };
};

export const uploadsFetchReset = () => {
    return {
        type: UPLOADS_FETCH_RESET
    };
};
