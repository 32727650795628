import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { EVOUCHER_FETCH, EVOUCHER_BARCODE_FETCH, EVOUCHER_UPDATE } from "../actions/types";
import { evoucherFetchSuccess } from "../actions/EvoucherActions";
import { showErrorMessage } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import axios from "axios";
import _ from "lodash";

// API
const evoucherFetchRequest = async id =>
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/evouchers/${id}`, {
            headers: authorizationHeader("bearer")
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

const evoucherBarcodeFetchRequest = async barcode =>
    await axios
        .get(`${process.env.REACT_APP_API_URL}/api/evouchers/barcode/${barcode}`, {
            headers: authorizationHeader("bearer")
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

const evoucherUpdateRequest = async values =>
    await axios
        .put(`${process.env.REACT_APP_API_URL}/api/evouchers/${values._id}`, values, {
            headers: authorizationHeader("bearer")
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });

// WORKER
function* evoucherFetch({ payload }) {
    try {
        const data = yield call(evoucherFetchRequest, payload);
        yield put(evoucherFetchSuccess(data));
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

function* evoucherBarcodeFetch({ payload }) {
    try {
        const data = yield call(evoucherBarcodeFetchRequest, payload);
        yield put(evoucherFetchSuccess(data));
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

function* evoucherUpdate({ payload }) {
    try {
        const data = yield call(evoucherUpdateRequest, payload);
        if (data.success) {
            yield put(evoucherFetchSuccess(data.success.result));
        }
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

// WATCHER
function* evoucherFetchWatcher() {
    yield takeEvery(EVOUCHER_FETCH, evoucherFetch);
}

function* evoucherBarcodeFetchWatcher() {
    yield takeEvery(EVOUCHER_BARCODE_FETCH, evoucherBarcodeFetch);
}

function* evoucherUpdateWatcher() {
    yield takeEvery(EVOUCHER_UPDATE, evoucherUpdate);
}

export default function* rootSaga() {
    yield all([
        fork(evoucherFetchWatcher),
        fork(evoucherBarcodeFetchWatcher),
        fork(evoucherUpdateWatcher)
    ]);
}
