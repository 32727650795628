import {
    EVOUCHER_FETCH,
    EVOUCHER_BARCODE_FETCH,
    EVOUCHER_FETCH_SUCCESS,
    EVOUCHER_FETCH_RESET,
    EVOUCHER_UPDATE
} from "./types";

export const evoucherFetch = id => {
    return {
        type: EVOUCHER_FETCH,
        payload: id
    };
};

export const evoucherBarcodeFetch = barcode => {
    return {
        type: EVOUCHER_BARCODE_FETCH,
        payload: barcode
    };
};

export const evoucherFetchSuccess = payload => {
    return {
        type: EVOUCHER_FETCH_SUCCESS,
        payload: payload
    };
};

export const evoucherFetchReset = () => {
    return {
        type: EVOUCHER_FETCH_RESET
    };
};

export const evoucherUpdate = values => {
	return {
		type: EVOUCHER_UPDATE,
		payload: values
	};
};