import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { hideMessage } from "../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "../util/IntlMessages";
import SignInAdminForm from "../components/form/authen/SignInAdminForm";
import SweetAlert from "react-bootstrap-sweetalert";
import { NotificationContainer, NotificationManager } from "react-notifications";

class SignIn extends React.Component {
	componentDidUpdate() {
		if (this.props.authUser !== null) {
			this.props.history.push("/");
		}
	}

	render() {
		const { match, showMessage, loader, alertMessage } = this.props;

		return (
			<div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
				<div className="app-login-main-content">
					<div className="app-logo-content d-flex align-items-center justify-content-center">
						<Link className="logo-lg" to="/">
							<img src={require("assets/images/logo.jpg")} className="img-fluid" alt="" />
						</Link>
					</div>
					{match.path === "/signin" && (
						<div className="app-login-content">
							<div className="app-login-header mb-4">
								<h1>
									<IntlMessages id="signin" />
								</h1>
								<h3>
									<IntlMessages id="signin.user" />
								</h3>
							</div>
							<div className="app-login-form">
								<SignInAdminForm />
							</div>
						</div>
					)}
				</div>
				{loader && (
					<div className="loader-view">
						<CircularProgress />
					</div>
				)}
				<SweetAlert
					show={showMessage}
					title={alertMessage}
					confirmBtnText={<IntlMessages id="commands.confirm" />}
					onConfirm={() => {
						this.props.hideMessage();
					}}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ auth: { authUser }, notify }) => {
	const { loader, alertMessage, showMessage } = notify;
	return { loader, alertMessage, showMessage, authUser };
};

export default connect(
	mapStateToProps,
	{
		hideMessage
	}
)(SignIn);
