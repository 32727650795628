import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { USER_FETCH, USERS_FETCH, USER_CREATE, USER_UPDATE, USER_DELETE } from "../actions/types";
import { userFetchSuccess, usersFetchSuccess } from "../actions/UserActions";
import { showErrorMessage, showSuccessMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import { intl } from "../lngProvider/IntlGlobalProvider";
import { reset } from "redux-form";
import axios from "axios";
import _ from "lodash";

// API
const userFetchRequest = async id =>
	await axios
		.get(`${process.env.REACT_APP_API_URL}/api/users/${id}`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const usersFetchRequest = async payload => {
	let url = `${process.env.REACT_APP_API_URL}/api/users`;
	let params = { ...payload };
	params = _.omitBy(params, _.isEmpty);

	return await axios
		.get(url, {
			headers: authorizationHeader("bearer"),
			params: params
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});
};

const userCreateRequest = async values =>
	await axios
		.post(`${process.env.REACT_APP_API_URL}/api/users`, values, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const userUpdateRequest = async values =>
	await axios
		.put(`${process.env.REACT_APP_API_URL}/api/users/${values._id}`, values, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const userDeleteRequest = async id =>
	await axios
		.delete(`${process.env.REACT_APP_API_URL}/api/users/${id}`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

// WORKER
function* userFetch({ payload }) {
	try {
		const data = yield call(userFetchRequest, payload);
		yield put(userFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* usersFetch({ payload }) {
	try {
		const data = yield call(usersFetchRequest, payload);
		yield put(usersFetchSuccess(data));
		yield put(hideLoader());
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* userCreate({ payload }) {
	try {
		const data = yield call(userCreateRequest, payload);
		yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated" })));
		yield put(reset("UserForm"));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* userUpdate({ payload }) {
	try {
		const data = yield call(userUpdateRequest, payload);
		if (data.success) {
			yield put(userFetchSuccess(data.success.result));
			yield put(showSuccessMessage(intl.formatMessage({ id: "success.updated" })));
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* userDelete({ payload: { id } }) {
	try {
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

// WATCHER
function* userFetchWatcher() {
	yield takeEvery(USER_FETCH, userFetch);
}

function* usersFetchWatcher() {
	yield takeEvery(USERS_FETCH, usersFetch);
}

function* userCreateWatcher() {
	yield takeEvery(USER_CREATE, userCreate);
}

function* userUpdateWatcher() {
	yield takeEvery(USER_UPDATE, userUpdate);
}

function* userDeleteWatcher() {
	yield takeEvery(USER_DELETE, userDelete);
}

export default function* rootSaga() {
	yield all([
		fork(userFetchWatcher),
		fork(usersFetchWatcher),
		fork(userCreateWatcher),
		fork(userUpdateWatcher),
		fork(userDeleteWatcher)
	]);
}
