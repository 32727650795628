import grey from "@material-ui/core/colors/grey";

export default {
	palette: {
		primary: {
			light: "#d91938",
			main: "#c61633",
			dark: "#ac142d",
			contrastText: "#fff"
		},
		secondary: {
			light: grey[300],
			main: grey[500],
			dark: grey[700],
			contrastText: "#fff"
		}
	},
	status: {
		danger: "orange"
	},
	typography: {
		button: {
			fontWeight: 400,
			textAlign: "capitalize"
		}
	},
	overrides: {
		MuiTableCell: {
			root: {
				padding: 5
			}
		}
	}
};
