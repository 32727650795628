import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { USER_SIGNIN, USER_SIGNOUT, USER_PROFILE_FETCH } from "../actions/types";
import { userSignInSuccess, userSignOutSuccess, userProfileFetchSuccess } from "../actions/AuthActions";
import { showErrorMessage, hideLoader } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import axios from "axios";

const signInWithEmailPasswordRequest = async (email, password) =>
	await axios
		.post(`${process.env.REACT_APP_API_URL}/api/auth/user`, {
			email,
			password
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const userProfileFetchRequest = async () =>
	await axios
		.get(`${process.env.REACT_APP_API_URL}/api/auth/profile`, {
			headers: authorizationHeader("bearer")
		})
		.then(response => response.data)
		.catch(error => {
			throw error;
		});

const signOutRequest = async () => {};

function* signInUserWithEmailPassword({ payload: { email, password } }) {
	try {
		const data = yield call(signInWithEmailPasswordRequest, email, password);
		if (data.success) {
			const { token, token_type } = data.success;
			localStorage.setItem("user", JSON.stringify({ token, token_type }));
			yield put(userSignInSuccess(data.success.user));
			yield put(hideLoader());
		}
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* userProfileFetch() {
	try {
		const data = yield call(userProfileFetchRequest);
		yield put(userProfileFetchSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

function* signOut() {
	try {
		const data = yield call(signOutRequest);
		localStorage.removeItem("user");
		yield put(userSignOutSuccess(data));
	} catch (error) {
		yield put(showErrorMessage(getErrorMessage(error)));
	}
}

export function* userSignInWatcher() {
	yield takeEvery(USER_SIGNIN, signInUserWithEmailPassword);
}

export function* userProfileFetchWatcher() {
	yield takeEvery(USER_PROFILE_FETCH, userProfileFetch);
}

export function* userSignOutWatcher() {
	yield takeEvery(USER_SIGNOUT, signOut);
}

export default function* rootSaga() {
	yield all([
		fork(userSignInWatcher),
		fork(userSignOutWatcher),
		fork(userProfileFetchWatcher)
	]);
}
