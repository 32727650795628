import appLocaleData from "react-intl/locale-data/th";
import thMessages from "../locales/th_TH.json";

const thLang = {
	messages: {
		...thMessages
	},
	locale: "th-TH",
	data: appLocaleData
};
export default thLang;
