import React from "react";
import { connect } from "react-redux";
import { evoucherUpdate } from "../../actions/EvoucherActions"
import Barcode from "react-barcode"
import TescoLotusEvoucher300 from "../../assets/images/barcode/lotus-e-coupons-300-honda-v04.jpg";
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import _ from "lodash";
import moment from "moment";
moment.locale('th');

class Honda2021001 extends React.Component {

    constructor(props) {
        super(props);
        this.state = { term: false, redeem: false };
    }

    render() {
        const { evoucher } = this.props;

        return (
            <div className="row">
                {evoucher && !_.isEmpty(evoucher.barcode) && !_.isEmpty(evoucher.telephone) && (
                    <div className="col">
                        <Modal isOpen={this.state.term && !this.state.redeem && evoucher.status != 'redeemed'}   >
                            <ModalBody   >
                                <h4 style={{fontFamily: "Kanit", fontWeight: 600}} >คุณต้องการใช้อี-คูปองบัตรกำนัลโลตัสมูลค่า 300 บาทใช่หรือไม่ ?</h4>
                                <p style={{fontFamily: "Kanit", fontWeight: 400}} className="h5 pl-3">กด “ยืนยัน” เพื่อรับบาร์โค้ดในการชำระเงิน</p>
                                <p style={{fontFamily: "Kanit", fontWeight: 400}} className="h5 pl-3">กด “ไม่ใช่” หากต้องการใช้ภายหลัง</p>
                                <p style={{fontFamily: "Kanit", fontWeight: 400}} className="h5">เมื่อท่านใช้คูปองแลกสิทธิ์แล้ว จะไม่สามารถนำคูปองกลับมาใช้ได้อีก</p>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn-danger btn mr-auto" style={{ fontFamily: "Kanit", width: 120, backgroundColor: "#8f3705", borderColor: "#8f3705" }}
                                    onClick={() => {
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "redeemed", redeemed_date: new Date() });
                                        this.setState({ redeem: true });
                                    }}
                                >ยืนยัน</button>
                                <button className="btn btn-outline-secondary" style={{ fontFamily: "Kanit", width: 120 }} onClick={() => { this.setState({ term: false }) }} >ไม่ใช่</button>
                            </ModalFooter>
                        </Modal>
                        <div style={{ position: "relative" }}>
                            <img src={TescoLotusEvoucher300} className="img-fluid" />
                            {(this.state.redeem || evoucher.status == 'redeemed') && (
                                <p className="pt-2 pb-2 pl-4 pr-4" style={{ color: 'red', fontSize: 36, position: 'absolute', bottom: 50, left: 50, border: "3px solid red", transform: "rotate(30deg)" }}>REDEEMED.</p>
                            )}
                        </div>
                        {!this.state.redeem && evoucher.status != 'redeemed' && (
                            <div className="">
                                <div className="mt-4  mb-4" >
                                    <h4 style={{fontFamily: "Kanit", fontWeight: 400}} className="text-center mt-4 mb-4">วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`}<br />เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}</h4>
                                    <h4 style={{fontFamily: "Kanit", fontWeight: 600}}>เงื่อนไขการใช้อี-คูปองบัตรกำนัลโลตัสมูลค่า 300 บาท จากบริษัท ฮอนด้า ออโตโมบิล (ประเทศไทย) จำกัด</h4>
                                    <ul className="h5 list-unstyled ">
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">1. E-Coupons ไม่สามารถถอน เปลี่ยนเป็นเงินสด หรือรูปแบบการชำระอื่น และไม่สามารถทอนส่วนต่างเป็นเงินสด กรณีซื้อสินค้าน้อยกว่ามูลค่าของ E-Coupons</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">2. สามารถใช้ซื้อสินค้าที่โลตัส ทุกสาขา และต้องใช้ก่อนวันหมดอายุ</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">3. สามารถใช้ได้เพียงครั้งเดียว</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">4. ไม่สามารถใช้ซื้อสินค้าประเภทเครื่องดื่มแอลกอฮอล์ บุหรี่ บัตรเติมเงิน บัตรของขวัญ นมดัดแปลงสาหรับทารกและนมดัดแปลงสูตรต่อเนื่องสาหรับทารกและเด็ก (สูตร 1 และ 2) Bill Payment  และค่าธรรมเนียม บริการไปรษณีย์ ศูนย์อาหาร โลตัสช้อปออนไลน์ และสินค้า/ร้านค้าในบริเวณพื้นที่เช่าของโลตัส</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">5. การใช้ E-Coupons จะต้องกดรับสิทธิ์ที่หน้าเคาเตอร์แคชเชียร์เท่านั้น ขอสงวนสิทธิ์ไม่ให้ใช้รูปภาพที่มีการบันทึกเก็บไว้ หรือพิมพ์บนกระดาษ</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">6. ส่วนลดจากการใช้ จะไม่ถูกนำมาคำนวณเป็นแต้มคลับการ์ด</li>
                                        <li style={{fontFamily: "Kanit", fontWeight: 400}} className="mt-2">7. บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขโดยไม่จำเป็นต้องแจ้งให้ทราบล่วงหน้า</li>
                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn-lg h2" style={{fontFamily: "Kanit", fontWeight: 400}} onClick={() => {
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "opened", opened_date: new Date() });
                                        this.setState({ term: true });
                                    }}>กดใช้สิทธิ์</button>
                                </div>
                            </div>
                        )}
                        {(this.state.redeem || evoucher.status == 'redeemed') && (
                            <div className="text-center mt-4">
                                <Barcode value={evoucher.barcode} />
                                <h4 className="mt-2" style={{fontFamily: "Kanit", fontWeight: 400}} >โปรดแสดงบาร์โค้ดนี้ ณ จุดรับชำระเงิน</h4>
                                <h4 className="mt-4" style={{fontFamily: "Kanit", fontWeight: 400}} >วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`}<br />เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}</h4>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({ evoucher: { evoucher } }) => {
    return { evoucher };
};

export default connect(
    mapStateToProps, { evoucherUpdate }
)(Honda2021001);
