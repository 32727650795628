export const authorizationHeader = tokenType => {
	let user = JSON.parse(localStorage.getItem("user"));
	tokenType = user && user.tokenType ? user.token_type : tokenType;
	if (user && user.token) {
		return { Authorization: `${tokenType} ${user.token}` };
	} else {
		return {};
	}
};

export const authorizationHeaderWithMultipartFormData = tokenType => {
	let user = JSON.parse(localStorage.getItem("user"));
	tokenType = user.token_type ? user.token_type : tokenType;
	if (user && user.token) {
		return { Authorization: `${tokenType} ${user.token}`, "Content-Type": `multipart/form-data` };
	} else {
		return {};
	}
};

export const getErrorMessage = error => {
	if (error.response) {
		if (error.response.data && error.response.data.error && error.response.data.error.name && error.response.data.error.message) {
			return `${error.response.data.error.name ? error.response.data.error.name : ""} ${
				error.response.data.error.message ? error.response.data.error.message : ""
				}`;
		} else if (error.response.data || error.response.data.message) {
			return `${error.response.data ? error.response.data : ""} ${
				error.response.data.message ? error.response.data.message : ""
				}`;
		} else if (error.response.status || error.response.statusText) {
			return `${error.response.status} : ${error.response.statusText}`;
		}
	} else {
		return error.message;
	}
};
