import {
    REPORT_EVOUCHER_RESPONSERATE_FETCH_SUCCESS,
    REPORT_EVOUCHER_RESPONSERATE_FETCH_RESET
} from "../actions/types";

const INIT_STATE = {
    reportEvoucherResponseRate: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REPORT_EVOUCHER_RESPONSERATE_FETCH_RESET:
            return {
                ...state,
                reportEvoucherResponseRate: {}
            }
        case REPORT_EVOUCHER_RESPONSERATE_FETCH_SUCCESS:
            return {
                ...state,
                reportEvoucherResponseRate: action.payload
            }
        default:
            return state;
    }
}
