import React from 'react';

const Footer = () => {
        return (
            <footer className="app-footer">
                <div className="d-flex flex-row justify-content-between">
                    <div>
                        <span>Copyright NISSAN &copy; {new Date().getFullYear()}</span>
                    </div>
                </div>
            </footer>
        );
    }
;

export default Footer;
