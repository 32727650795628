import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { USER_TYPES, USER_ROOT_TYPE, USER_ADMIN_TYPE } from "../../constants/Systems";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";

class SidenavContent extends Component {
	componentDidUpdate() {
		const { history } = this.props;
		const that = this;
		const pathname = `${history.location.pathname}`; // get current path

		const menuLi = document.getElementsByClassName("menu");
		for (let i = 0; i < menuLi.length; i++) {
			menuLi[i].onclick = function (event) {
				for (let j = 0; j < menuLi.length; j++) {
					const parentLi = that.closest(this, "li");
					if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains("open"))) {
						menuLi[j].classList.remove("open");
					}
				}
				this.classList.toggle("open");
			};
		}

		const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
		try {
			const activeNav = this.closest(activeLi, "ul"); // select closest ul
			if (activeNav.classList.contains("sub-menu")) {
				this.closest(activeNav, "li").classList.add("open");
			} else {
				this.closest(activeLi, "li").classList.add("open");
			}
		} catch (error) { }
	}

	closest(el, selector) {
		try {
			let matchesFn;
			// find vendor prefix
			["matches", "webkitMatchesSelector", "mozMatchesSelector", "msMatchesSelector", "oMatchesSelector"].some(function (
				fn
			) {
				if (typeof document.body[fn] == "function") {
					matchesFn = fn;
					return true;
				}
				return false;
			});

			let parent;

			// traverse parents
			while (el) {
				parent = el.parentElement;
				if (parent && parent[matchesFn](selector)) {
					return parent;
				}
				el = parent;
			}
		} catch (e) { }

		return null;
	}

	render() {
		const { authUser } = this.props;

		return (
			<CustomScrollbars className=" scrollbar">
				<ul className="nav-menu">
					<li className="menu no-arrow">
						<NavLink to="/app/main">
							<i className="zmdi zmdi-home zmdi-hc-fw" />
							<span className="nav-text">
								<IntlMessages id="main" />
							</span>
						</NavLink>
					</li>
				</ul>

				{authUser && authUser.role == USER_ROOT_TYPE && (
					<ul className="nav-menu">
						<li className="nav-header mt-0" style={{ backgroundColor: '#c61633', color: "#FFF" }} >
							<IntlMessages id="evoucher" />
						</li>
						<li className="menu no-arrow">
							<NavLink to="/app/evoucher/import">
								<i className="zmdi zmdi-file zmdi-hc-fw" />
								<span className="nav-text">
									<IntlMessages id="evoucher.import" />
								</span>
							</NavLink>
						</li>

					</ul>
				)}



				{authUser && authUser.role == USER_ROOT_TYPE && (
					<ul className="nav-menu">
						<li className="nav-header mt-0" style={{ backgroundColor: '#c61633', color: "#FFF" }} >
							<IntlMessages id="setting" />
						</li>

						<li className="menu no-arrow">
							<NavLink to="/app/user">
								<i className="zmdi zmdi-account zmdi-hc-fw" />
								<span className="nav-text">
									<IntlMessages id="user" />
								</span>
							</NavLink>
						</li>
					</ul>
				)}
			</CustomScrollbars>
		);
	}
}

const mapStateToProps = ({ auth: { authUser } }) => {
	return { authUser };
};

export default withRouter(
	connect(
		mapStateToProps,
		{}
	)(SidenavContent)
);
