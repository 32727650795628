import { EVOUCHER_FETCH_SUCCESS, EVOUCHER_FETCH_RESET } from "../actions/types";

const INIT_STATE = {
    evoucher: {}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case EVOUCHER_FETCH_SUCCESS: {
            return {
                ...state,
                evoucher: action.payload
            };
        }
        case EVOUCHER_FETCH_RESET: {
            return {
                ...state,
                evoucher: {}
            };
        }
        default:
            return state;
    }
};
