import { intlShape } from "react-intl";

var INTL;
const IntlGlobalProvider = (props, context) => {
	INTL = context.intl;
	return props.children;
};

IntlGlobalProvider.contextTypes = {
	intl: intlShape.isRequired
};

var instance;
class IntlTranslator {
	constructor() {
		if (!instance) {
			instance = this;
		}
		return instance;
	}

	formatMessage(message, values) {
		return INTL.formatMessage(message, values);
	}
}

export const intl = new IntlTranslator();
export default IntlGlobalProvider;
