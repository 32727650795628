import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as reduxForm } from "redux-form";
import AuthReducer from "./AuthReducer";
import NotifyReducer from "./NotifyReducer";
import SettingReducer from "./SettingReducer";
import UserReducer from "./UserReducer";
import ReportReducer from "./ReportReducer";
import UploadReducer from "./UploadReducer";
import EvoucherReducer from "./EvoucherReducer";

export default history =>
	combineReducers({
		router: connectRouter(history),
		setting: SettingReducer,
		notify: NotifyReducer,
		auth: AuthReducer,
		user: UserReducer,
		upload: UploadReducer,
		report: ReportReducer,
		evoucher: EvoucherReducer,
		form: reduxForm
	});
