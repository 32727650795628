import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { userSignOut, userProfileFetch } from "../../../actions/AuthActions";
import IntlMessages from "util/IntlMessages";

class UserInfo extends React.Component {
	componentDidMount() {
		const { authUser, userProfileFetch } = this.props;
		if (!authUser._id) {
			userProfileFetch();
		}
	}

	state = {
		anchorEl: null,
		open: false
	};

	handleClick = event => {
		this.setState({ open: true, anchorEl: event.currentTarget });
	};

	handleRequestClose = () => {
		this.setState({ open: false });
	};

	render() {
		const { authUser } = this.props;

		return (
			<div className="user-profile d-flex flex-row align-items-center">
				<Avatar alt="..." src={require("assets/images/avatar.png")} className="user-avatar " />
				<div className="user-detail">
					<h4 className="user-name" onClick={this.handleClick}>
						{authUser.first_name} {authUser.last_name} <i className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
					</h4>
				</div>
				<Menu
					className="user-info"
					id="simple-menu"
					anchorEl={this.state.anchorEl}
					open={this.state.open}
					onClose={this.handleRequestClose}
					PaperProps={{
						style: {
							minWidth: 120,
							paddingTop: 0,
							paddingBottom: 0
						}
					}}
				>
					<MenuItem
						onClick={() => {
							this.handleRequestClose();
							this.props.userSignOut();
						}}
					>
						<i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

						<IntlMessages id="signout" />
					</MenuItem>
				</Menu>
			</div>
		);
	}
}

const mapStateToProps = ({ setting, auth: { authUser } }) => {
	const { locale } = setting;
	return { locale, authUser };
};
export default connect(
	mapStateToProps,
	{ userSignOut, userProfileFetch }
)(UserInfo);
