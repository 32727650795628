// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Auth const
export const USER_SIGNIN = "user_signin";
export const USER_SIGNIN_SUCCESS = "user_signin_success";
export const USER_SIGNOUT = "user_signout";
export const USER_SIGNOUT_SUCCESS = "user_signout_success";
export const USER_PROFILE_FETCH = "user_profile_fetch";
export const USER_PROFILE_FETCH_SUCCESS = "user_profile_fetch_success";
export const INIT_URL = "init_url";

// User
export const USER_CREATE = "user_create";
export const USER_UPDATE = "user_update";
export const USER_DELETE = "user_delete";
export const USER_FETCH = "user_fetch";
export const USER_FETCH_SUCCESS = "user_fetch_success";
export const USER_FETCH_RESET = "user_fetch_reset";
export const USERS_FETCH = "users_fetch";
export const USERS_FETCH_SUCCESS = "users_fetch_success";
export const USERS_FETCH_RESET = "users_fetch_reset";

// Notification
export const HIDE_MESSAGE = "hide_message";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const SHOW_INFO_MESSAGE = "show_info_message";
export const SHOW_SUCCESS_MESSAGE = "show_success_message";
export const SHOW_WARNING_MESSAGE = "show_warning_message";
export const SHOW_ERROR_MESSAGE = "show_error_message";

// UPLOAD
export const UPLOADS_FETCH = "uploads_fetch";
export const UPLOADS_FETCH_SUCCESS = "uploads_fetch_success";
export const UPLOADS_FETCH_RESET = "uploads_fetch_reset";

// REPORT
export const REPORT_EVOUCHER_RESPONSERATE_EXPORT_EXCEL = "report_evoucher_responserate_export_excel";
export const REPORT_EVOUCHER_RESPONSERATE_FETCH = "report_evoucher_responserate_fetch";
export const REPORT_EVOUCHER_RESPONSERATE_FETCH_SUCCESS = "report_evoucher_responserate_fetch_success";
export const REPORT_EVOUCHER_RESPONSERATE_FETCH_RESET = "report_evoucher_responserate_fetch_reset";

// EVOUCHER
export const EVOUCHER_FETCH = "evoucher_fetch";
export const EVOUCHER_BARCODE_FETCH = "evoucher_barcode_fetch";
export const EVOUCHER_FETCH_SUCCESS = "evoucher_fetch_success";
export const EVOUCHER_FETCH_RESET = "evoucher_fetch_reset";
export const EVOUCHER_UPDATE = "evoucher_update";