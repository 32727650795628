import React from "react";
import { connect } from "react-redux";
import { evoucherUpdate } from "../../actions/EvoucherActions"
import Barcode from "react-barcode"
import EvoucherV1 from "../../assets/images/barcode/lotus-e-coupons-1000-mazda-2024002.jpg";
import EvoucherV2 from "../../assets/images/barcode/lotus-e-coupons-1000-mazda-2024003.jpg";
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import _ from "lodash";
import moment from "moment";
moment.locale('th');

class Mazda2024001 extends React.Component {

    constructor(props) {
        super(props);
        this.state = { term: false, redeem: false, redeemed_date: null };
    }

    render() {
        const { evoucher } = this.props;

        return (
            <div className="row">
                {evoucher && !_.isEmpty(evoucher.barcode) && (
                    <div className="col">
                        <Modal isOpen={this.state.term && !this.state.redeem && evoucher.status != 'redeemed'}   >
                            <ModalBody>
                                <h4><strong>คุณต้องการใช้คูปองส่วนลดในการเช็กระยะมูลค่า 500 บาท ในการเข้ารับบริการในครั้งนี้ใช่หรือไม่ ?</strong></h4>
                                <p className="h5 pl-3">กด “ยืนยัน” เพื่อใช้สิทธิ์ทันที</p>
                                <p className="h5 pl-3">กด “ยกเลิก” เพื่อใช้สิทธิ์ภายหลัง</p>
                                <p className="h5">เมื่อกดยืนยันแล้ว คูปองจะไม่สามารถนำกลับมาใช้ได้</p>
                                <p className="h5">
                                    กรุณากดรับสิทธิ์การใช้คูปองที่<span style={{ textDecoration: "underline" }}>ศูนย์บริการ ต่อหน้าเจ้าหน้าที่ที่ปรึกษาด้านบริการเท่านั้น</span> และไม่สามารถรับสิทธิ์โดยการบันทึกภาพหน้าจอมือถือได้
                                </p>

                            </ModalBody>
                            <ModalFooter>
                                <button className="btn-danger btn mr-auto" style={{ fontFamily: "mazdatype", width: 120, backgroundColor: "#8f3705", borderColor: "#8f3705" }}
                                    onClick={() => {
                                        let now = new Date()
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "redeemed", redeemed_date: now });
                                        this.setState({ redeem: true, redeemed_date: now });
                                    }}
                                >ยืนยัน</button>
                                <button className="btn btn-outline-secondary" style={{ fontFamily: "mazdatype", width: 120 }} onClick={() => { this.setState({ term: false }) }} >ยกเลิก</button>
                            </ModalFooter>
                        </Modal>
                        <div className={(evoucher.status == 'redeemed' || evoucher.status == 'active' && moment() >= moment(evoucher.expired_date)) ? "image-container" : ""} style={{ position: "relative" }}>
                            <img src={EvoucherV2} className="img-fluid" style={{ opacity: (evoucher.status == 'redeemed' || evoucher.status == 'active' && moment() >= moment(evoucher.expired_date)) ? "50%" : "100%" }} />
                            {(this.state.redeem || evoucher.status == 'redeemed') && (
                                <p className="content pt-2 pb-2 pl-4 pr-4" style={{ color: '#ffff', fontSize: "5vw", fontFamily: "mazdatype", fontWeight: 'lighter', position: 'absolute', bottom: '4px', right: '20px', border: "2.25pt solid #ffff",  }}>REDEEMED</p>
                            )}
                            {evoucher.status == 'active' && moment() >= moment(evoucher.expired_date) && (
                                <p className="content pt-2 pb-2 pl-4 pr-4" style={{ color: '#ffff', fontSize: "5vw", fontFamily: "mazdatype", fontWeight: 'lighter', position: 'absolute',  bottom: '4px', right: '20px', border: "2.25pt solid #ffff", }}>EXPIRED</p>
                            )}
                        </div>
                        {!this.state.redeem && evoucher.status != 'redeemed' && (moment() < moment(evoucher.expired_date)) && (
                            <div className="">
                                <div className="mt-4  mb-4" >
                                    <h4 className="text-center"></h4>
                                    <h4 className="text-center"> </h4>
                                    <h4 className="text-center mt-4 mb-4">
                                        รุ่นรถ : {evoucher.model}<br />
                                        หมายเลขตัวถัง : {evoucher.chassis}<br />
                                        วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`} เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}</h4>
                                    <h4><strong>เงื่อนไขการใช้คูปองส่วนลดเงินสดในการตรวจเช็กระยะมูลค่า 500 บาท</strong></h4>
                                    <ul className="h5 list-unstyled ">
                                        <li className="mt-2">1. สิทธิ์คูปองส่วนลดนี้สำหรับเลขตัวถังรถยนต์มาสด้าที่ระบุข้างต้นเท่านั้น ไม่สามารถมอบหรือโอนสิทธิ์ให้กับรถยนต์มาสด้าคันอื่นได้</li>
                                        <li className="mt-2">2. กรุณาแจ้งสิทธิ์คูปองส่วนลดที่ศูนย์บริการ เพื่อทำการตรวจเช็กสิทธิ์ก่อนเข้ารับบริการทุกครั้ง</li>
                                        <li className="mt-2">3. กรุณากดรับสิทธิ์การใช้คูปองที่<span style={{ textDecoration: "underline" }} >ศูนย์บริการ ต่อหน้าเจ้าหน้าที่ที่ปรึกษาด้านบริการ</span> หลังจากที่เจ้าหน้าที่ได้ทำการเช็กสิทธิ์ และยืนยันว่าลูกค้าได้รับสิทธิ์แล้ว<span style={{ textDecoration: "underline" }}>เท่านั้น</span> หากมีการกดรับสิทธิ์ก่อน จะถือว่าสละสิทธิ์ในการใช้คูปอง</li>
                                        <li className="mt-2">4. สงวนสิทธิ์การใช้คูปองส่วนลด 1 ใบ ต่อการเช็กระยะ 1  ครั้งเท่านั้น </li>
                                        <li className="mt-2">5. คูปองนี้ไม่สามารถโอน, เปลี่ยน, แลก หรือ ทอนเป็นเงินสดได้</li>
                                        <li className="mt-2">6. เงื่อนไขการใช้คูปองเป็นไปตามที่บริษัทฯกำหนด และไม่สามารถรับสิทธิ์โดยการบันทึกภาพหน้าจอมือถือได้</li>
                                        <li className="mt-2">7. กรุณาตรวจสอบรายละเอียดและเงื่อนไขการใช้คูปองได้ที่ <a href="https://www.mazda.co.th/services-thank-you-campaign">https://www.mazda.co.th/services-thank-you-campaign</a></li>
                                        <li className="mt-2">8. สอบถามเพิ่มเติม MAZDA SPEEDLINE: 02-030-5666</li>

                                    </ul>
                                </div>
                                <div className="text-center mt-4">
                                    <button className="btn-lg h2" onClick={() => {
                                        this.props.evoucherUpdate({ _id: evoucher._id, status: "opened", opened_date: new Date() });
                                        this.setState({ term: true });
                                    }}>กดใช้สิทธิ์</button>
                                </div>
                            </div>
                        )}
                        {(this.state.redeem || evoucher.status == 'redeemed') && (
                            <div className="text-center mt-4">
                                <h4 className="text-center mt-4 mb-4">
                                    รุ่นรถ : {evoucher.model}<br />
                                    หมายเลขตัวถัง : {evoucher.chassis}<br />
                                    วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`} เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}
                                </h4>
                                <h4 className="mt-4">คูปองถูกใช้ : {`${moment(this.state.redeemed_date ? this.state.redeemed_date : evoucher.redeemed_date).format('D MMMM')} ${moment(this.state.redeemed_date ? this.state.redeemed_date : evoucher.redeemed_date).year() + 543}`}<br />เวลา {`${moment(this.state.redeemed_date ? this.state.redeemed_date : evoucher.redeemed_date).format("HH:mm")} น.`}</h4>
                            </div>
                        )}
                        {evoucher.status == 'active' && moment() >= moment(evoucher.expired_date) && (
                            <div className="text-center mt-4">
                                <h4 className="text-center mt-4 mb-4">
                                    รุ่นรถ : {evoucher.model}<br />
                                    หมายเลขตัวถัง : {evoucher.chassis}<br />
                                    วันหมดอายุ : {`${moment(evoucher.expired_date).format('D MMMM')} ${moment(evoucher.expired_date).year() + 543}`} เวลา {`${moment(evoucher.expired_date).format("HH:mm")} น.`}
                                </h4>
                                <h4 className="mt-4">คูปองหมดอายุ </h4>
                            </div>
                        )}
                    </div>
                )
                }
            </div>
        )
    }
}

const mapStateToProps = ({ evoucher: { evoucher } }) => {
    return { evoucher };
};

export default connect(
    mapStateToProps, { evoucherUpdate }
)(Mazda2024001);