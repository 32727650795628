import {
    UPLOADS_FETCH_SUCCESS,
    UPLOADS_FETCH_RESET
} from "../actions/types";

const INIT_STATE = {
    uploads: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOADS_FETCH_SUCCESS: {
            return {
                ...state,
                uploads: action.payload
            };
        }
        case UPLOADS_FETCH_RESET: {
            return {
                ...state,
                uploads: []
            };
        }
        default:
            return state;
    }
};
