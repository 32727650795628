import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { showLoader, userSignIn } from "../../../actions";
import { renderFields, validateField } from "../../../util/formUtil";
import { signInAdminFormFields } from "../formFields";
import IntlMessages from "../../../util/IntlMessages";
import Button from "@material-ui/core/Button";

class SignInAdminForm extends Component {
	render() {
		const { handleSubmit, formValues } = this.props;

		return (
			<form
				onSubmit={handleSubmit(() => {
					this.props.showLoader();
					this.props.userSignIn(formValues);
				})}
				noValidate
			>
				<fieldset>
					{renderFields(signInAdminFormFields)}
					<div className="mb-3 d-flex align-items-center justify-content-between">
						<Button type="submit" variant="contained" color="primary">
							<IntlMessages id="signin" />
						</Button>
					</div>
				</fieldset>
			</form>
		);
	}
}

function validate(values) {
	const errors = {};
	signInAdminFormFields.forEach(({ name, required, type }) => {
		if (!values[name] && required) {
			errors[name] = <IntlMessages id="errors.field.required" />;
			return;
		}
		if (type === "email" && !validateField(type, values[name])) {
			errors[name] = <IntlMessages id="errors.field.email" />;
			return;
		}
	});
	return errors;
}

const mapStateToProps = ({ form }) => {
	const formValues = form.SignInAdminForm ? form.SignInAdminForm.values : null;
	return { formValues };
};

SignInAdminForm = reduxForm({
	validate,
	form: "SignInAdminForm"
})(SignInAdminForm);

export default withRouter(
	connect(
		mapStateToProps,
		{ userSignIn, showLoader }
	)(SignInAdminForm)
);
