import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    UPLOADS_FETCH
} from "../actions/types";
import { uploadsFetchSuccess} from "../actions/UploadActions";
import { showErrorMessage } from "../actions/NotifyActions";
import { authorizationHeader, getErrorMessage } from "../util/apiUtil";
import { intl } from "../lngProvider/IntlGlobalProvider";
import axios from "axios";
import _ from "lodash";

// API
const uploadsFetchRequest = async payload => {
    let url = `${process.env.REACT_APP_API_URL}/api/uploads`;
    let params = { ...payload };
    params = _.omitBy(params, _.isEmpty);

    return await axios
        .get(url, {
            headers: authorizationHeader("bearer"),
            params: params
        })
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
};


// WORKER
function* uploadsFetch({ payload }) {
    try {
        const data = yield call(uploadsFetchRequest, payload);
        yield put(uploadsFetchSuccess(data));
    } catch (error) {
        yield put(showErrorMessage(getErrorMessage(error)));
    }
}

// WATCHER
function* uploadsFetchWatcher() {
    yield takeEvery(UPLOADS_FETCH, uploadsFetch);
}


export default function* rootSaga() {
    yield all([
        fork(uploadsFetchWatcher),
    ]);
}
