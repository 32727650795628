import {
	CHANGE_NAVIGATION_STYLE,
	FIXED_DRAWER,
	HORIZONTAL_MENU_POSITION,
	INSIDE_THE_HEADER,
	SWITCH_LANGUAGE,
	TOGGLE_COLLAPSED_NAV,
	VERTICAL_NAVIGATION,
	WINDOW_WIDTH
} from "../actions/types";

const initialSettings = {
	navCollapsed: false,
	drawerType: FIXED_DRAWER,
	width: window.innerWidth,
	navigationStyle: VERTICAL_NAVIGATION,
	horizontalNavPosition: INSIDE_THE_HEADER,
	locale: {
		languageId: "thai",
		locale: "th",
		name: "Thai",
		icon: "th"
	}
};

const settings = (state = initialSettings, action) => {
	switch (action.type) {
		case "@@router/LOCATION_CHANGE":
			return {
				...state,
				navCollapsed: false
			};
		case TOGGLE_COLLAPSED_NAV:
			return {
				...state,
				navCollapsed: action.isNavCollapsed
			};
		case WINDOW_WIDTH:
			return {
				...state,
				width: action.width
			};
		case SWITCH_LANGUAGE:
			return {
				...state,
				locale: action.payload
			};
		case CHANGE_NAVIGATION_STYLE:
			return {
				...state,
				navigationStyle: action.payload
			};
		case HORIZONTAL_MENU_POSITION:
			return {
				...state,
				horizontalNavPosition: action.payload
			};
		default:
			return state;
	}
};

export default settings;
