const languageData = [
	{
		languageId: "thai",
		locale: "th",
		name: "ไทย",
		icon: "th"
	},
	{
		languageId: "english",
		locale: "en",
		name: "English",
		icon: "us"
	}
];
export default languageData;
