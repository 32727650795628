import {
    REPORT_EVOUCHER_RESPONSERATE_EXPORT_EXCEL,
    REPORT_EVOUCHER_RESPONSERATE_FETCH,
    REPORT_EVOUCHER_RESPONSERATE_FETCH_RESET,
    REPORT_EVOUCHER_RESPONSERATE_FETCH_SUCCESS
} from "./types";

export const reportEvoucherResponserateExportExcel = payload => {
    return {
        type: REPORT_EVOUCHER_RESPONSERATE_EXPORT_EXCEL,
        payload: payload
    };
};

export const reportEvoucherResponserateFetch = payload => {
    return {
        type: REPORT_EVOUCHER_RESPONSERATE_FETCH,
        payload: payload
    };
}

export const reportEvoucherResponserateFetchReset = () => {
    return {
        type: REPORT_EVOUCHER_RESPONSERATE_FETCH_RESET
    }
}

export const reportEvoucherResponserateFetchSuccess = payload => {
    return {
        type: REPORT_EVOUCHER_RESPONSERATE_FETCH_SUCCESS,
        payload: payload
    };
}
