import { all } from "redux-saga/effects";
import AuthSagas from "./AuthSagas";
import UserSagas from "./UserSagas";
import ReportSagas from "./ReportSagas";
import UploadSagas from "./UploadSagas";
import EvoucherSagas from "./EvoucherSagas";


export default function* rootSaga(getState) {
	yield all([AuthSagas(), UserSagas(), ReportSagas(), UploadSagas(), EvoucherSagas()]);
}
