import {
	USER_CREATE,
	USER_UPDATE,
	USER_DELETE,
	USER_FETCH,
	USER_FETCH_SUCCESS,
	USERS_FETCH,
	USERS_FETCH_SUCCESS,
	USER_FETCH_RESET,
	USERS_FETCH_RESET
} from "./types";

export const userFetch = id => {
	return {
		type: USER_FETCH,
		payload: id
	};
};

export const userFetchSuccess = payload => {
	return {
		type: USER_FETCH_SUCCESS,
		payload: payload
	};
};

export const userFetchReset = () => {
	return {
		type: USER_FETCH_RESET
	};
};

export const usersFetch = payload => {
	return {
		type: USERS_FETCH,
		payload: payload
	};
};

export const usersFetchSuccess = payload => {
	return {
		type: USERS_FETCH_SUCCESS,
		payload: payload
	};
};

export const usersFetchReset = () => {
	return {
		type: USERS_FETCH_RESET
	};
};

export const userCreate = values => {
	return {
		type: USER_CREATE,
		payload: values
	};
};

export const userUpdate = values => {
	return {
		type: USER_UPDATE,
		payload: values
	};
};

export const userDelete = id => {
	return {
		type: USER_DELETE,
		payload: id
	};
};
